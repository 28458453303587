<template>
  <div
    id="dragging_container"
    :style="{
      transform: `translate(${translateX}px, ${translateY}px) scale(${scale})`,
    }"
    @mousedown="startDragging"
    @mousemove="onDrag"
    @mouseup="stopDragging"
    @mouseleave="stopDragging"
    @touchstart="handleTouchStart"
    @touchmove="handleTouchMove"
    @touchend="handleTouchEnd"
    style="position: absolute">
    <div
      id="NaviMap"
      class="map"
      style="--pos-x: 0px; --pos-y: 0px; --scale: 1">
      <img src="@/assets/img/map.png" draggable="false" />

      <!-- Map Canvas -->
      <div id="MapCanvas">
        <div
          v-for="point in mapPoints"
          :key="point.id"
          :style="{
            '--x': point.x + 'px',
            '--y': point.y + 'px',
            background: point.active
              ? 'orange'
              : point.visited
              ? '#ff9422'
              : 'transparent',
          }"
          class="mapPoint testing">
          {{ point.id }}
        </div>

        <div
          v-for="connector in connectors"
          :key="connector.id"
          :style="{
            background: connector.visited ? '#22aaff' : 'transparent',
            ...getConnectorStyle(connector),
          }"
          class="mapConnector testing">
          <span>{{
            Math.round(
              calculateDistance(
                mapPoints[connector.p1],
                mapPoints[connector.p2],
              ),
            ) /
              10 +
            'm'
          }}</span>
        </div>

        <div
          v-if="start.p !== null"
          :style="{
            '--x': mapPoints[start.p].x + 'px',
            '--y': mapPoints[start.p].y + 'px',
          }"
          class="mapPin pinStart testing"></div>

        <div
          v-if="end.p !== null"
          :style="{
            '--x': mapPoints[end.p].x + 'px',
            '--y': mapPoints[end.p].y + 'px',
          }"
          class="mapPin pinEnd testing"></div>
      </div>
    </div>
  </div>
</template>

<script>
import mapPoints from '@/assets/data/points.json';
import mapConnectors from '@/assets/data/connectors.json';

export default {
  name: 'NaviMap',
  props: {
    selectedRoom: {
      type: Object,
      required: false,
    },
    start: {
      type: Object,
      required: true,
    },
    end: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      mapPoints,
      connectors: mapConnectors,
      scale: 1, // Initial scale for pinch-zoom
      translateX: -600,
      translateY: -300,
      touchData: null, // For tracking pinch-zoom touch events
      dragData: null, // For tracking drag events
      selectedPoint: null,
      queue: [],
      visited: [],
      distanceTable: [],
    };
  },
  methods: {
    // Pinch-Zoom Handlers
    handleTouchStart(event) {
      if (event.touches.length === 2) {
        const [touch1, touch2] = event.touches;
        this.touchData = {
          initialDistance: this.getDistance(touch1, touch2),
          initialScale: this.scale,
        };
      } else if (event.touches.length === 1) {
        const touch = event.touches[0];
        this.dragData = {
          startX: touch.clientX - this.translateX,
          startY: touch.clientY - this.translateY,
        };
      }
    },
    handleTouchMove(event) {
      if (this.touchData && event.touches.length === 2) {
        const [touch1, touch2] = event.touches;
        const currentDistance = this.getDistance(touch1, touch2);
        const scaleChange = currentDistance / this.touchData.initialDistance;
        this.scale = Math.min(
          Math.max(this.touchData.initialScale * scaleChange, 0.5),
          3,
        ); // Clamped between 0.5 and 3
      } else if (this.dragData && event.touches.length === 1) {
        const touch = event.touches[0];
        this.translateX = touch.clientX - this.dragData.startX;
        this.translateY = touch.clientY - this.dragData.startY;
      }
    },
    handleTouchEnd() {
      this.touchData = null;
      this.dragData = null;
    },
    getDistance(touch1, touch2) {
      const dx = touch2.clientX - touch1.clientX;
      const dy = touch2.clientY - touch1.clientY;
      return Math.sqrt(dx * dx + dy * dy);
    },

    // Mouse Drag Handlers
    startDragging(event) {
      this.dragData = {
        startX: event.clientX - this.translateX,
        startY: event.clientY - this.translateY,
      };
    },
    onDrag(event) {
      if (this.dragData) {
        this.translateX = event.clientX - this.dragData.startX;
        this.translateY = event.clientY - this.dragData.startY;
      }
    },
    stopDragging() {
      this.dragData = null;
    },

    // Connector and Map Position Updates
    getConnectorStyle(connector) {
      const p1 = this.mapPoints.find((point) => point.id === connector.p1);
      const p2 = this.mapPoints.find((point) => point.id === connector.p2);

      const distance = Math.sqrt(
        Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2),
      );

      const angle = 90 + Math.atan2(p2.y - p1.y, p2.x - p1.x) * (180 / Math.PI);

      return {
        '--x1': `${p1.x}px`,
        '--y1': `${p1.y}px`,
        '--x2': `${p2.x}px`,
        '--y2': `${p2.y}px`,
        '--distance': `${distance}px`,
        '--r': `${angle}deg`,
      };
    },
    calculateDistance(p1, p2) {
      return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
    },

    // Distance Table Preparation
    prepareDistanceTable() {
      for (let i = 0; i < this.mapPoints.length; i++) {
        let row = { pointID: i };
        for (let j = 0; j < this.mapPoints.length; j++) {
          row['distanceToPoint-' + j] = Infinity;
          row['nextToPoint-' + j] = null;
        }
        this.distanceTable.push(row);
      }
      for (let i = 0; i < this.mapPoints.length; i++) {
        this.distanceTable[i]['distanceToPoint-' + i] = 0;
        this.distanceTable[i]['nextToPoint-' + i] = i;

        let connectors = this.connectors.filter(
          (c) => c.p1 === i || c.p2 === i,
        );
        connectors.forEach((c) => {
          let next = c.p1 === i ? c.p2 : c.p1;
          this.distanceTable[i]['distanceToPoint-' + next] =
            this.calculateDistance(this.mapPoints[i], this.mapPoints[next]);
          this.distanceTable[i]['nextToPoint-' + next] = next;
          this.distanceTable[i]['connectorToPoint-' + next] = c;
        });
      }

      for (let from = 0; from < this.mapPoints.length; from++)
        for (let to = 0; to < this.mapPoints.length; to++)
          for (let through = 0; through < this.mapPoints.length; through++) {
            let distance =
              this.distanceTable[from]['distanceToPoint-' + through] +
              this.distanceTable[through]['distanceToPoint-' + to];

            if (distance < this.distanceTable[from]['distanceToPoint-' + to]) {
              this.distanceTable[from]['distanceToPoint-' + to] = distance;
              this.distanceTable[from]['nextToPoint-' + to] =
                this.distanceTable[from]['nextToPoint-' + through];
              this.distanceTable[from]['connectorToPoint-' + to] =
                this.distanceTable[from]['connectorToPoint-' + through];
            }
          }
    },

    // Pathfinding using Distance Table
    useDistanceTable() {
      this.mapPoints.forEach((point) => (point.visited = false));
      this.connectors.forEach((connector) => (connector.visited = false));

      let path = [];
      let from = this.start.p;
      let to = this.end.p;

      while (from !== to && from !== null && to !== null) {
        this.mapPoints[from].visited = true;

        const connector = this.distanceTable[from]['connectorToPoint-' + to];
        if (connector !== null && connector !== undefined && connector.id) {
          this.connectors[connector.id].visited = true;
        }

        path.push(from);
        from = this.distanceTable[from]['nextToPoint-' + to];

        if (from === null || from === undefined) {
          alert('No path exists.');
          break;
        }
      }
      if (from === to) {
        path.push(to);
        this.mapPoints[to].visited = true;
      }
      this.$emit('updateNavigationPath', path);
    },

    // Add a New Point to Map
    addPoint(event) {
      const rect = event.target.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      this.mapPoints.push({
        id: this.mapPoints.length,
        x,
        y,
        visited: false,
      });
    },

    // Save Points and Connections
    savePointsAndConnections() {
      localStorage.setItem('mapPoints', JSON.stringify(this.mapPoints));
      localStorage.setItem('connectors', JSON.stringify(this.connectors));
    },

    // Point Selection
    selectPoint(point) {
      if (this.selectedPoint === null) {
        this.selectedPoint = point;
        this.selectedPoint.active = true;
      } else if (this.selectedPoint == point) {
        this.selectedPoint.active = false;
        this.selectedPoint = null;
      } else {
        this.connectors.push({
          id: this.connectors.length,
          p1: this.selectedPoint.id,
          p2: point.id,
          visited: false,
        });
        this.selectedPoint.active = false;
        this.selectedPoint = null;
      }
    },
  },
  watch: {
    selectedRoom(newRoom) {
      if (newRoom && newRoom.name && newRoom.name.length > 0) {
        // this.updateMapPosition(newRoom);
        return;
      }
    },
    start(newStart) {
      if (newStart && newStart.p !== null) {
        this.useDistanceTable();
      }
    },
  },
  mounted() {
    this.queue.push({ p: this.start.p, parent: null });
    this.prepareDistanceTable();
    // this.useDistanceTable();
  },
};
</script>

<style scoped>
@import url('@/assets/css/NaviMap.css');
</style>
