<template>
  <div id="DynamicIsland">
    <div id="IslandScreenSearch" style="display: flex" :style="islandHeight">
      <div class="_1st">
        <input
          type="text"
          placeholder="Search..."
          v-model="searchInput"
          @input="handleInput"
          :disabled="isRoomSelected"
          id="searchInput" />
        <img
          src="@/assets/svg/search.svg"
          class="IconButton"
          v-if="!showRemoveIcon && !navigateButtonClicked"
          id="searchIcon" />
        <img
          src="@/assets/svg/exit.svg"
          class="IconButton"
          v-if="showRemoveIcon"
          id="cancel"
          @click="removeSelectedRoom" />
      </div>
      <hr
        v-if="navigateButtonClicked"
        style="
          height: 0.4rem;
          width: 100%;
          border-radius: 10vw;
          opacity: 0.4;
          background: #000;
        " />
      <div>
        <input
          id="fromInput"
          type="text"
          placeholder="From"
          v-model="searchInputFrom"
          @input="handleFromInput"
          v-if="navigateButtonClicked" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DynamicIsland',
  data() {
    return {
      searchInput: '',
      searchInputFrom: '',
      showRemoveIcon: false,
      islandHeight: { height: '4rem' },
    };
  },
  props: {
    selectedRoom: {
      type: Object,
      required: false,
    },
    navigateButtonClicked: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    isRoomSelected() {
      return (
        this.selectedRoom &&
        this.selectedRoom.name &&
        this.selectedRoom.name.length > 0
      );
    },
  },
  methods: {
    handleInput() {
      this.$emit('updateSearchInput', this.searchInput);
    },
    handleFromInput() {
      this.$emit('updateSearchInput', this.searchInputFrom);
    },
    removeSelectedRoom() {
      this.$emit('updateSearchInput', '');
      this.$emit('updateSelectedRoom', {});
      this.$emit('updateNavigateButtonClicked', false);
      this.searchInput = '';
      this.showRemoveIcon = false;
    },
  },
  watch: {
    selectedRoom(newRoom) {
      this.showRemoveIcon = this.isRoomSelected;
      if (this.isRoomSelected) {
        this.searchInput = newRoom.name;
      } else {
        this.searchInput = '';
      }
    },
    navigateButtonClicked(isClicked) {
      if (isClicked) {
        this.islandHeight = { height: '10rem' };
        this.showRemoveIcon = false;
      }
    },
  },
};
</script>

<style scoped>
@import url('@/assets/css/DynamicIsland.css');
</style>
