<template>
  <div id="BottomBar" @click="minimize">
    <h3 id="BottomTitle">{{ bottomTitle }}</h3>
    <p id="BottomDescription" v-if="!isMinimized">
      {{ bottomDescription }}
    </p>
    <button id="BottomButton" @click="handleNavigateToClick">
      NAVIGATE TO <img src="@/assets/svg/paper-plane.svg" alt="To" />
    </button>
  </div>
</template>

<script>
export default {
  name: 'BottomBar',
  props: {
    searchInput: {
      type: String,
      required: false,
    },
    selectedRoom: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      bottomTitle: 'Loading',
      bottomDescription: '',
      navigateButtonClicked: false,
      isMinimized: false,
      isOnHomeScreen: true,
    };
  },
  methods: {
    handleNavigateToClick() {
      this.$emit('updateNavigateButtonClicked', true);
      this.navigateButtonClicked = true;
      document.getElementById('BottomButton').style.display = 'none';
      document.getElementById('BottomBar').style.display = 'none';
    },
    minimize() {
      this.isMinimized = !this.isMinimized;
    },
    checkWhatBuildingIn() {
      // this.bottomTitle = 'Loading.';
      this.bottomDescription = '';
      // get the gps coordinates
      //     {
      //   "name": "Design Center",
      //   "points": [
      //     {
      //       "lat": 52.113458,
      //       "lng": 21.115043
      //     },
      //     {
      //       "lat": 52.11364,
      //       "lng": 21.115306
      //     },
      //     {
      //       "lat": 52.113361,
      //       "lng": 21.115847
      //     },
      //     {
      //       "lat": 52.11315,
      //       "lng": 21.115557
      //     }
      //   ]
      // },
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            console.log('Latitude: ', latitude);
            console.log('Longitude: ', longitude);
            const buildings = require('@/assets/data/buildingGPS.json');

            let closestBuilding = null;
            let closestDistance = Infinity;

            buildings.forEach((building) => {
              const polygon = building.points;
              let centerLat = 0;
              let centerLng = 0;

              polygon.forEach((point) => {
                centerLat += point.lat;
                centerLng += point.lng;
              });

              centerLat /= polygon.length;
              centerLng /= polygon.length;

              const distance = Math.sqrt(
                Math.pow(centerLat - latitude, 2) +
                  Math.pow(centerLng - longitude, 2),
              );

              if (distance < closestDistance) {
                closestDistance = distance;
                closestBuilding = building;
              }
            });

            if (closestBuilding) {
              const distanceInMeters = closestDistance * 111139; // Convert degrees to meters (approximation)
              if (distanceInMeters > 500) {
                this.bottomTitle = 'Too Far from Building';
                this.bottomDescription = '';
                return;
              }
              this.bottomTitle = closestBuilding.name;
              this.bottomDescription = '';
            } else {
              this.bottomTitle = 'Unknown';
              this.bottomDescription = '';
            }
          },
          (error) => {
            console.error('Error getting geolocation: ', error);
            this.bottomTitle = 'Unknown';
            this.bottomDescription = '';
          },
        );
      } else {
        alert('Geolocation is not supported by this browser.');
        this.bottomTitle = 'Unknown';
        this.bottomDescription = '';
      }
    },
  },
  mounted() {
    this.checkWhatBuildingIn();
    setInterval(() => {
      if (this.isOnHomeScreen) {
        this.checkWhatBuildingIn();
      }
    }, 100);
  },
  watch: {
    searchInput(newInput) {
      document.getElementById('BottomBar').style.display =
        newInput.length > 0 ? 'none' : 'flex';
    },
    selectedRoom(room) {
      if (room.name) {
        if (!room || room.name.length < 1) {
          // if no room is selected or room is empty
          this.isOnHomeScreen = true;
          this.checkWhatBuildingIn();
          return;
        }
      } else {
        this.checkWhatBuildingIn();
        this.isOnHomeScreen = true;
        document.getElementById('BottomButton').style.display = 'none';
        return;
      }
      this.isOnHomeScreen = false;
      this.bottomTitle = room.name;
      this.bottomDescription = `This ${room.type} is located on ${
        room.floor == 0 ? 'the ground floor' : 'the ' + room.floor + ' floor'
      } and in the ${room.building}`;

      document.getElementById('BottomBar').style.display = 'flex';
      console.log('NEW ROOM: ', room);

      document.getElementById('BottomButton').addEventListener('click', () => {
        console.log('Navigating to room', room);
      });
      document.getElementById('BottomButton').style.display = 'flex';
    },
  },
};
</script>

<style scoped>
@import url('@/assets/css/BottomBar.css');
</style>
