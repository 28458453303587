<template>
  <div>
    <TestingDragging v-if="false" />
    <NaviMap
      :selectedRoom="selectedRoom"
      :start="start"
      :end="end"
      @updateNavigationPath="updateNavigationPath" />
    <SearchResults
      v-if="searchInput"
      :searchInput="searchInput"
      :navigateButtonClicked="navigateButtonClicked"
      @updateSelectedRoom="updateSelectedRoom"
      @updateSelectedRoomTo="updateSelectedRoomTo"
      style="z-index: 10" />
    <DynamicIsland
      @updateSearchInput="updateSearchInput"
      @updateSelectedRoom="updateSelectedRoom"
      @updateNavigateButtonClicked="updateNavigateButtonClicked"
      @updateCurrentPoint="updateCurrentPoint"
      :navigateButtonClicked="navigateButtonClicked"
      :selectedRoom="selectedRoom"
      :navigationPath="navigationPath" />
    <BottomBar
      :searchInput="searchInput"
      :selectedRoom="selectedRoom"
      :navigationPath="navigationPath"
      :currentPoint="currentPoint"
      @updateNavigateButtonClicked="updateNavigateButtonClicked" />
  </div>
</template>

<script>
import NaviMap from '@/components/NaviMap.vue';
import DynamicIsland from '@/components/DynamicIsland.vue';
import BottomBar from '@/components/BottomBar.vue';
import SearchResults from '@/components/SearchResults.vue';
import mapPoints from '@/assets/data/points.json';
import TestingDragging from './components/TestingDragging.vue';

export default {
  name: 'App',
  data() {
    return {
      searchInput: '',
      selectedRoom: {},
      start: { p: null },
      end: { p: null },
      navigateButtonClicked: false,
      mapPoints,
      currentPoint: 0,
      navigationPath: [18, 17, 10, 11, 12, 13],
    };
  },
  components: {
    NaviMap,
    DynamicIsland,
    BottomBar,
    SearchResults,
    TestingDragging,
  },
  methods: {
    updateCurrentPoint(value) {
      this.currentPoint = value;
    },
    updateNavigationPath(value) {
      this.navigationPath = value;
    },
    updateSearchInput(value) {
      this.searchInput = value;
    },
    updateSelectedRoom(value) {
      console.log('updateSelectedRoom', value);
      this.selectedRoom = value;

      this.end.p = Object.keys(this.mapPoints).find(
        (key) => this.mapPoints[key].room === value.name,
      );
      this.$emit('end', this.end);
      console.log('end.p', this.end.p);
    },
    updateSelectedRoomTo(value) {
      this.start.p = Object.keys(this.mapPoints).find(
        (key) => this.mapPoints[key].room === value.name,
      );

      console.log('start.p', this.start.p);
      this.$emit('start', this.start);
      this.$forceUpdate();
    },
    updateNavigateButtonClicked(value) {
      this.navigateButtonClicked = value;
    },
  },
};
</script>

<style>
@import '@/assets/css/base.css';
@import '@/assets/css/App.css';
</style>
