<template>
  <div
    ref="testElement"
    :style="{
      width: '100px',
      height: '100px',
      backgroundColor: 'red',
      position: 'absolute',
    }">
    Drag Me
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useDraggable } from '@vueuse/core';

const testElement = ref(null);

useDraggable(testElement, {
  initialValue: { x: 50, y: 50 },
});
</script>
